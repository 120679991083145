(function(TIPTOE, w, d, $) {
	/** @type {Function} Custom logger */
	var log = TIPTOE.HAS_DEBUG
		? window.console.log.bind(window, 'Newsletter:')
		: function() {};

	/** @type {Object} Default newsletter options */
	var defaults = {
		timer: false,
	};


	/**
	 * Newsletter class
	 * @param  {Object}     options Options for the instance
	 * @return {Newsletter}         The created instance
	 */
	function Newsletter(options) {
		if (!(this instanceof Newsletter)) return new Newsletter();

		this.options = $.extend(true, defaults, options);

		this.$popIn = $('#js-pop-newsletter');
		this.$btnNewsletter = $('.btn--newsletter');
		this.$successNewsletter = $('.newsletter__success');
		this.$inner = this.$popIn.find('.newsletter__inner');
		this.$close = this.$popIn.find('.close-newsletter');
		this.waitTime = 10000;
		this.timer = null;
		this.cookieName = 'newsletter';
		this.cookieValue = 'display';

		log('constructor', this.options);

		this.init();

		return this;
	}


	/**
	 * Init Pop In
	 */
	Newsletter.prototype.init = function() {
		log('init');
		var _ = this;

		// Close on echap
		$(document).keyup(function(e) {
			if (e.keyCode == 27) {
				_.closeNewsletter();
			}
		});

    if (typeof KlaviyoSubscribe !== 'undefined') {
  		KlaviyoSubscribe.attachToForms('#tt-klaviyo', {
  			success: function(e) {
  				_.$successNewsletter.addClass('is-visible');

  				var formId = $('#tt-klaviyo').find('input[name="g"]').val();
  				window.dataLayer = window.dataLayer || [];
  				if (formId && (window.dataLayer !== undefined || typeof window.dataLayer.push === 'function')) {
  					window.dataLayer.push({
  						event: 'newsletter',
  						form: {
  							form_id: formId,
  						},
  					});
  				}

  				setTimeout(function() {
  					_.closeNewsletter();
  				}, 2000);
  			}
      });
    }

		_.$btnNewsletter.on('click', _.openNewsletter.bind(_));
		_.$popIn.on('click', _.closeNewsletter.bind(_));

		_.$inner.on('click', function(e) {
			e.stopPropagation();
		});

		// Close on click
		_.$close.on('click', _.closeNewsletter.bind(_));

		// Only show the pop-in newsletter if we haven't have it in our cookies
		if (!_.hasBeenDisplaid()) {
			_.setTimer();
		}

		$('.sib_signup_form').submit(function(e) {
      var targetFormId = $(e.target).find('input[name="sib_form_id"]');
      var formId = targetFormId ? targetFormId.val() : null;
      // dataLayer
      window.dataLayer = window.dataLayer || [];
      if (
        formId &&
        (window.dataLayer !== undefined ||
        typeof window.dataLayer.push === 'function')
      ) {
        window.dataLayer.push({
          event: 'newsletter',
          form: {
            form_id: formId,
          },
        });
      }
		});
	};


	/**
	 * Sets timer at 10s
	 */
	Newsletter.prototype.setTimer = function() {
		log('setTimer');
		var _ = this;

		// Do no instantiate timer if not active
		if (!_.options.timer) {
			log('timer is disabled, stopping here.');
			return;
		}

		clearTimeout(_.timer);
		// Display afte X
		_.timer = setTimeout(_.openNewsletter.bind(_), _.waitTime);
	}


	/**
	 * Changes the value of cookie if the pop-in has been displayed
	 */
	Newsletter.prototype.hasBeenDisplaid = function() {
		var hasBeenDisplaid = Cookies.get(this.cookieName) === this.cookieValue;
		log('hasBeenDisplaid', hasBeenDisplaid);
		return hasBeenDisplaid;
	}


	/**
	 * Adds is-visible class to open the pop-in
	 */
	Newsletter.prototype.openNewsletter = function() {
		log('openNewsletter');
		this.$popIn.addClass('is-visible');
	}


	/**
	 * Close Pop in
	 */
	Newsletter.prototype.closeNewsletter = function () {
		log('closeNewsletter');
		this.$popIn.removeClass('is-visible');
		this.$successNewsletter.removeClass('is-visible');

		this.setCookie();
	};


	/**
	 * Set Cookie to accepeted
	 */
	Newsletter.prototype.setCookie = function() {
		if (Cookies.get(this.cookieName) !== this.cookieValue) {
			// 30 days expiration cookie
			log('setCookie 30days for newsletter');
			Cookies.set(this.cookieName, this.cookieValue, { expires : 30 })
		} else {
			log('cookie already set, stopping here.');
		}
	};



	// Expose contstructor globally
	window.Newsletter = Newsletter;


})(TIPTOE, window, document, jQuery);
